import { graphql, useStaticQuery } from 'gatsby'
import * as React from 'react'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import PropTypes from 'prop-types'

function RecaptchaProvider({ children }) {
  const {
    wp: {
      allSettings: { reCaptchaSiteKey },
    },
  } = useStaticQuery(graphql`
    query reCaptchaSiteKeyQuery {
      wp {
        allSettings {
          reCaptchaSiteKey
        }
      }
    }
  `)

  return (
    <GoogleReCaptchaProvider reCaptchaKey={reCaptchaSiteKey}>
      {children}
    </GoogleReCaptchaProvider>
  )
}
RecaptchaProvider.propTypes = {
  children: PropTypes.element.isRequired,
}

RecaptchaProvider.defaultProps = {}

export default RecaptchaProvider
