/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */
import React from 'react'
import RecaptchaProvider from './src/providers/RecaptchaProvider'
// You can delete this file if you're not using it

// export { default as wrapRootElement } from 'src/apollo/providers'

export const shouldUpdateScroll = ({ routerProps }) => {
  const restoreScrollState =
    routerProps.location?.state?.restoreScrollState || false
  return !restoreScrollState
}
export const wrapRootElement = ({ element }) => (
  <RecaptchaProvider>{element}</RecaptchaProvider>
)
